import React from "react"
import {graphql} from "gatsby"

import imgPage from "../images/headers/Logos_Ale_Preowned.svg"
import Layout from "../components/layout.en"
import SEO from "../components/seo"
import style from "./preowned.module.scss"
import Header from "../components/header";
import Img from "gatsby-image"

//import LogoJetCraft from "../images/preowned/logo_jetcraft.png"
import Foto1 from "../images/preowned/foto1.jpg"

import Logo1 from "../images/preowned/icon_money.png"
import Logo2 from "../images/preowned/icon_document.png"
import Logo3 from "../images/preowned/icon_lupa.png"
import Logo4 from "../images/preowned/icon_engrane.png"
import Logo5 from "../images/preowned/icon_check.png"
import Logo6 from "../images/preowned/icon_conect.png"

import Logo7 from "../images/preowned/logo_asa.png"
import Logo8 from "../images/preowned/logo_iada.png"
import Logo9 from "../images/preowned/logo_jssi.png"
import PreownedDoc from "../docs/Preowned.pdf"
//import PreownedDocCat from "../docs/Catalogo_Master.pdf"
import Form from "../components/form.en";
import Preowned from "../images/icons/preowned.png";
import JetCraft from "../images/icons/jetcraft.png";

export default ({data}) => {
    const extraInfo =
        [
            {name: '', mail: 'info@ale.mx', phone: '554 202 0920'},
        ];
    return (
        <Layout extraInfo={extraInfo}>

            <Header LogoPage={imgPage}/>
            <SEO title="Preowned"/>

            <div className={style.preCover}>
                <Img fluid={data.portada.childImageSharp.fluid}/>
                <a href={PreownedDoc} download={true}>DOWNLOAD OUR BROCHURE</a>
            </div>


            <div className={style.preInfo}>
                <h1>Ale Preowned</h1>
                <p>Ale Preowned is the internationally recognized pre-owned aircraft sales area and where we build
                    long-term professional relationships where we offer support and advice throughout the process of
                    purchase-sale of your aircraft. Having unique guarantees and offering our expertise as the only
                    certified appraisers in México.</p>
                <Form align="bottom" options={['PreOwned: Aircraft Brokerage']}/>

            </div>

            <div className={style.jetcraft}>
                <div className={style.left}>
                    <div className={style.logos}>
                        <img src={Preowned} alt={'Preowned'}/>
                        <span></span>
                        <img src={JetCraft} alt={'Jetcraft'}/>
                    </div>
                    <a href="http://jetcraft.com/" target="_blank" rel="noopener noreferrer">Learn More</a>
                </div>
                <div className={style.right}>
                    <p><b>AUTHORIZED REPRESENTATIVE</b></p>
                    <p>Two executive aviation leaders have formed a strategic alliance in México, Central America and
                        the Caribbean with one of the largest inventories in the region, generating an attractive offer
                        for customers looking to purchase, sell, change their aircraft or consulting. This alliance adds
                        Jetcraft's global reach to Ale PreOwned's local knowledge, combining over a century of combined
                        experience and building an alliance that will put the skies at your reach.</p>
                </div>
            </div>

            <div className={style.preDo}>

                <div className={style.title}>
                    <p>WHAT WE DO</p>
                </div>

                <div className={style.infoBlock}>
                    <div className={style.info}>
                        <ul>
                            <li><img alt="Preowned" src={Logo1}/><p>ACQUISITION, SALE AND EXCHANGE OF AIRCRAFTS</p>
                            </li>
                            <li><img alt="Preowned" src={Logo2}/><p>STRUCTURING TRANSACTIONS</p></li>
                            <li><img alt="Preowned" src={Logo3}/><p>VALUATION OF AIRCRAFT CONDITIONS AND VALUE</p>
                            </li>
                            <li><img alt="Preowned" src={Logo4}/><p>INDUSTRY INTELLIGENCE AND MARKET RESEARCH</p></li>
                            <li><img alt="Preowned" src={Logo5}/><p>ALTERNATIVE PROCUREMENT SOLUTIONS AND POSSESSION
                                STRATEGIES</p></li>
                            <li><img alt="Preowned" src={Logo6}/><p>FLEET PLANNING</p></li>
                        </ul>
                    </div>
                    <img src={Foto1} alt="Lorem"/>
                </div>
                <div className={style.title}>
                    <p>PRESENCE IN CENTRAL AMERICA AND THE CARIBBEAN</p>
                    <span> </span>
                </div>
                <Img fluid={data.mapa.childImageSharp.fluid}/>

            </div>

            <div className={style.preCertificates}>
                <h2>OUR CERTIFICATIONS</h2>
                <div className={style.item}>
                    <img src={Logo7} alt="Certificates"/>
                    <p>The American Society of Appraisers is the largest multidisciplinary, voluntary membership,
                        business association that represents and promotes its member appraisers. The society was founded
                        in 1936 and it is one of the eight business association evaluation groups that founded The
                        Appraisal Foundation.</p>
                </div>
                <div className={style.item}>
                    <img src={Logo9} alt="Certificates"/>
                    <p>Jet Support Services, Inc. is the largest provider of maintenance work for commercial aircrafts,
                        the company offers hourly cost maintenance programs for corporate and private aircraft engines,
                        APUs and fuselages.</p>
                </div>
                <div className={style.item}>
                    <img src={Logo8} alt="Certificates"/>
                    <p>The International Aircraft Dealers Association is the collective force that influences and shapes
                        the aircraft transaction industry, developing standards for efficient, effective and ethical
                        commercial aircraft transactions. With values of highly qualified professionals in aircraft
                        transactions for its success.</p>
                </div>
            </div>

            <div className={style.preDown}>
                <Img fluid={data.portadaAbajo.childImageSharp.fluid}/>
                <a href={'https://aerolineasejecutivas.wixsite.com/alepreowned'} target={'_blank'} rel="noreferrer">KNOW OUR CATALOG</a>
            </div>

        </Layout>
    )
}

export const query = graphql`
    query ImagesPreownedEn {
    portada: file(relativePath: {eq: "preowned/portada.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    portadaAbajo: file(relativePath: {eq: "preowned/foto_abajo.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    mapa: file(relativePath: {eq: "preowned/mapa.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}`





